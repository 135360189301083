import {LinearProgress} from '@material-ui/core';
import {Button} from '@material-ui/core';
import React from 'react';

export default function ProgressBar ({increment, maxValue, onBarFullEvent, children}) {
  const [progress, updateProgress] = React.useState(0);

  const handleClick = () => {
    if (progress >= maxValue) {
      updateProgress(0);
      onBarFullEvent();
    } else {
      updateProgress(progress + increment);
    }
  };

  return (
    <>
      <LinearProgress variant="determinate" value={progress} />
      <Button onClick={handleClick}>{children}</Button>
    </>
  );
}
