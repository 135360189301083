import {spawners} from './datas/spawners.js'
import {items} from './datas/items.js'

import ResourceSpawn from './components/ResourceSpawn.js';

function App() {
  const rockSpawnerArray = [
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
  ];
  const woodSpawnerArray = [
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
  ];
  const riceSpawnerArray = [
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
    [items.empty, items.empty, items.empty],
  ];

  return (
    <>
      <ResourceSpawn spawner={spawners.quarry} itemArray={rockSpawnerArray} />
      <ResourceSpawn spawner={spawners.forest} itemArray={woodSpawnerArray} />
      <ResourceSpawn spawner={spawners.field} itemArray={riceSpawnerArray} />
    </>
  );
}

export default App;
