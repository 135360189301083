import '../styles/ResourceSpawn.css'

import React from 'react';
import ItemGrid from './ItemGrid.js'
import ProgressBar from './ProgressBar.js'
import { spawnItem } from '../utils.js';

export default function ResourceSpawn({spawner, itemArray}) {

  const [spawnerArray, updateSpawnerArray] = React.useState(itemArray.map((element) => {
    return [...element];
  }));

  const handleBarFullEvent = () => {
    updateSpawnerArray(spawnItem(spawnerArray, spawner.item))
  };

  return (
    <div className="resource-spawn">
      <p className="resource-spawn-name">{spawner.name}</p>
      <ItemGrid itemArray={spawnerArray} />
      <ProgressBar increment={10} maxValue={90} onBarFullEvent={handleBarFullEvent}>{spawner.action}!</ProgressBar>
    </div>
  );
}
