import rock from '../assets/rock.png'
import wood from '../assets/wood.png'
import rice from '../assets/rice.png'

export const items = {
  rock : {
    name : 'rock',
    icon : rock,
    id : 'rk00'
  },
  wood : {
    name : 'wood',
    icon : wood,
    id : 'wd01'
  },
  rice : {
    name : 'rice',
    icon : rice,
    id : 're02'
  },
  empty : {
    name : 'empty',
    id : 'ey03'
  }
}
