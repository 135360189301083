import '../styles/ItemGrid.css'

import React from 'react';

export default function ItemGrid({itemArray}) {
  return (
    <div className="item-grid">
      {itemArray.map((itemRow, rowIndex) => (
        <div key={rowIndex} className="item-grid-row">
          {itemRow.map((item, colIndex) => (<ItemTile key={colIndex} item={item}/>))}
        </div>
      ))}
    </div>
  );
}

function ItemTile ({item}) {
  if (item.name !== 'empty') {
    return (<img src={item.icon} alt={item.name} className="item-grid-tile"/>);
  } else {
    return (<p className="item-grid-tile"></p>);
  }
}
