import {items} from './items.js';

export const spawners = {
  quarry : {
    name : 'Quarry',
    action : 'Mine',
    item : items.rock,
    id : 'qy00'
  },
  forest : {
    name : 'Forest',
    action : 'Chop',
    item : items.wood,
    id : 'ft01'
  },
  field : {
    name : 'Field',
    action : 'Reap',
    item : items.rice,
    id : 'fd02' 
  }
}
