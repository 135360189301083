import {items} from './datas/items.js'

export function spawnItem(itemArray, newItem) {
  const emptyCoord = getEmptyCoord(itemArray);
  const spawnedArray = itemArray.map((element, rowIndex) => {
    if (rowIndex === emptyCoord.rowIndex) {
      if (emptyCoord.colIndex >= 0 && emptyCoord.colIndex < element.length) {
        return [...element.slice(0, emptyCoord.colIndex), newItem, ...element.slice(emptyCoord.colIndex + 1)];
      } else {
        throw new Error("Out of range array access in spawnedArray while replacing new item at indices : " + JSON.stringify(emptyCoord) + "in array : " + JSON.stringify(itemArray));
      }
    } else {
      return [...element];
    }
  });

  return spawnedArray;
}

export function getEmptyCoord(itemArray, coord = {rowIndex : 0, colIndex : 0}) {
  if (coord.rowIndex >= itemArray.length) {
    return {rowIndex : -1, colIndex : -1};
  }
  if (itemArray[coord.rowIndex][coord.colIndex] === items.empty) {
    return coord;
  } else {
    if (coord.colIndex >= itemArray[coord.rowIndex].length) { //We have reached the end of the line
      return getEmptyCoord(itemArray, {rowIndex : ++coord.rowIndex, colIndex : 0});
    } else {
      return getEmptyCoord(itemArray, {rowIndex : coord.rowIndex, colIndex : ++coord.colIndex});
    }
  }
}
